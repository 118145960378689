import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Computer from "@material-ui/icons/Computer";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import AutoRenew from "@material-ui/icons/Autorenew";
import Home from "@material-ui/icons/Home";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(productStyle);

export default function SectionProduct() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      {/*
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h2 className={classes.title}>Objectives</h2>
          <h5 className={classes.description}>
            Axis Pools supports the decentrilization of the Cardano Network. Our nodes are implemented for high availability
            and Security. We also plan to give back to the Cardano Community and Our local Community buy building DAPs and Creating Jobs. 
            Read more about us below.
          </h5>
        </GridItem>
      </GridContainer>
      */}
      <div>
        <GridContainer>
          <GridItem xs={8} sm={3} md={3}>
            <InfoArea
              title="Architecture"
              description="Our first pool is implemented with two relays, one main core, and one core on standby. 
              If one relay fails, the other relay can handle communications. If the main core fails, the relays will switch over to the standy core.
              We plan to expand this to run more pools in the future. There is a maximum number of pools we want to build because Decentralization is important to us."
              icon={Computer}
              iconColor="primary"
              vertical
            />
          </GridItem>
          <GridItem xs={8} sm={3} md={3}>
            <InfoArea
              title="Security"
              description="We implement the most current security practices in our network of nodes. This includes updating/upgrading 
              software regurlary, using secure connections, managing users, monitoring logins, creating regular backups, and much more."
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={8} sm={3} md={3}>
            <InfoArea
              title="Contribution to Cardano"
              description="We plan to create our own DAPs on Cardano. Our team has the knowledge and experience to create useful DAPs that we hope 
              will attract more users to the Cardano Network. Axis Pool will 
              also continue to help out other stake pool operators(SPO) and support new people who are interesting in becoming an SPO."
              icon={AutoRenew}
              iconColor="warning"
              vertical
            />
          </GridItem>
          <GridItem xs={8} sm={3} md={3}>
            <InfoArea
              title="Commitment to Hawaii"
              description="Locally, we hope to create more jobs. Too many of our local developers are looking for work overseas. This is because there 
              are only a few places offering work. By creating useful Dapps, we will drive the need to supportDapps and increase developer jobs in Hawaii."
              icon={Home}
              iconColor="info"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
