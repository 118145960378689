/*eslint-disable*/ import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import ADAFooter from "components/Footer/ADAFooter.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";

// Sections for this page
import SectionProduct from "./Sections/SectionProduct.js";
import SectionTeam from "./Sections/SectionTeam.js";
import SectionWork from "./Sections/SectionWork.js";

const useStyles = makeStyles(landingPageStyle);

export default function ADALandingPage({ ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      {/*
      <Header
        color="transparent"
        brand="Axis Cardano"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        changeColorOnScroll={{
          height: 250,
          color: "info"
        }}
        {...rest}
      />
      */}
      <Parallax image={require("assets/img/background2.png")} filter="dark" small>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={6} sm={3} md={3}>
              <h2 className={classes.title}>Axis Pool</h2>
              <h4>
                Ticker: <a href="https://adapools.org/pool/e9794106e9403e8fbe059b1a2728fa8f686cf6e90565f7ba542b2dca" style={{color: "lightblue"}}>
                  [AXIS]
                </a> <br/>
                Margin: 0.1% <br/>
                Fixed Fee: 340 ADA (Minimum)<br/>
                Pledge: 17k ADA <br/>
              </h4>
              {/* 
              <Button
                color="danger"
                size="lg"
                href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                target="_blank"
              >
                <i className="fas fa-play" />
                Watch video
              </Button>
              */}
              
            </GridItem>
            <GridItem xs={14} sm={8} md={8}>
              <h2 className={classes.title}>Objectives</h2>
              <h5>
              We are a Hawaii based team of Software Developers. Our Mission is to give back
              to the Cardano community and our local community by building Dapps, creating jobs, and inspiring the next 
              generation of Engineers. 
              </h5>
              <br/>
            <br/>
            <br/>
            <br/>
              </GridItem>
              <br/>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <SectionProduct />
          <SectionTeam />
          {/*
          <SectionWork />
          */}
          </div>
      </div>
      <ADAFooter/>
    </div>
  );
}
