/* eslint-disable */
import React from "react";
import Favorite from "@material-ui/icons/Favorite";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-pro-react/components/footerStyle.js";

import Footer from "./Footer.js";

const useStyles = makeStyles(styles);

export default function ADAFooter(props) {
  const classes = useStyles();
  return (
    <Footer
        content={
          <div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()},
              {" "}
              <a
                href="https://www.htechstudio.com"
              >
                H Tech Studio
              </a>{" "}
              <Favorite className={classes.icon} />

            </div>
          </div>
        }
      />
  );
}

